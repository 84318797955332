<template>
	<div class="home">
		<div class="jpm_logo_top">
			<div><img src="@/assets/image/logo.svg" /></div>
			<div>
				<P class="loginbt" @click="changekefu">{{ $t("fk") }}</P>
				<!-- <div class="navright" @click="changeLanguage">
					<span>{{ texType == 0 ? $t("gensuixitong") : $t("jiantizhongwen") }} </span>
				</div> -->
			</div>
		</div>
		<div class="jpm_defaults_wapper">
			<img src="@/assets/image/home_bannera.jpg" width="100%" />
			<!-- <div class="jpm_logob">
				<div class="logo-left">INFORMATION</div>
				<div class="logo-right">2024</div>
			</div> -->
		</div>

		<div class="content_a">
			<p>{{ $t("gongsijieshao") }}</p>
			{{ $t("aboutaa") }}
		</div>

		<div class="content_b">
			<img src="@/assets/image/banner_bg.jpg" />
			{{ $t("aboutab") }}
		</div>

		<div class="content_c">
			<p>{{ $t("aboutad") }}</p>
			{{ $t("aboutac") }}
		</div>

		<tab-bar></tab-bar>
	</div>
</template>

<script>
	import TabBar from "@/components/tabbar/TabBar.vue";
	import homeApi from "@/api/home";
	export default {
		name: "About",
		components: {
			TabBar,
		},
		computed: {},
		data() {
			return {
				showList: false,
				infoList: [],
				isShow: true,
				texType: localStorage.getItem("localetype"),
				time: ''
			};
		},
		methods: {
			info() {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				homeApi
					.heart("", "")
					.then((data) => {
						console.log(data.data);
						this.infoList = data.data.hit_notice;
						this.$toast.clear();
					})
					.catch((err) => {
						// alert(err)
						// this.$toast.clear();
						this.$toast.fail(err);
					});
			},
			changekefu() {
				this.$router.push({
					path: "/kefu",
				});
			},
		},
		mounted() {

		},
		created() {},
	};
</script>
<style lang="less" scoped>
	.home {
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.jpm_defaults_wapper {
		// background: url("../assets/image/home_bg.png");
		background-size: 100%;
		background-position: bottom;
		position: relative;
		padding-top: 1rem;
		// padding-top: 5rem;
	}
	
	
	.jpm_logo_top {
		padding: 0 0.6rem 0 0.6rem;
		height: 50px;
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
	
		img {
			width: 140px;
		}
		
		.loginbt{
			margin-top: 0px;
			color: #ccc;
			span{
				font-family: 'nstb';
				color: #aaa;
			}
		}
	}

	.jpm_logo {
		width: 40%;
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-left: .6rem;
		margin-bottom: 2.5rem;
		z-index: 999;

		img {
			width: 100%;
		}

	}

	.jpm_logoa {
		width: 50%;
		position: absolute;
		top: -30px;
		right: -20px;

		img {
			width: 100%;
		}

	}

	.jpm_logob {
		width: 80%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo-left {
		width: 70%;
		height: 44px;
		background: #aaa;
        line-height: 44px;
		font-size: 0.57rem;
		font-family: 'nstb';
		color: #fff;
		text-align: left;
		padding-left: 0.6rem;
		box-sizing: border-box;
	}

	.logo-right {
		width: 30%;
		height: 44px;
		line-height: 44px;
		background: #E31837;
		font-family: 'nstb';
		font-size: .56rem;
		color: #fff;
		border-radius: 0 5px 0 0;
	}


	.content_a {
		width: 100%;
		padding: .8rem 0.6rem 0 .6rem;
		text-align: left;
		font-size: .28rem;
		box-sizing: border-box;

		img {
			margin-bottom: 10px;
			border-radius: 5px;
			width: 100%;
		}
		
		p{
			font-family: 'nstb';
			font-size: .42rem;
			color: #000;
			margin-bottom: 5px;
		}
	}

	.content_b {
		width: 100%;
		padding: .6rem .6rem 0 0.6rem;
		text-align: left;
		font-size: .28rem;
		box-sizing: border-box;

		img {
			margin-bottom: 10px;
			border-radius: 5px;
			width: 100%;
		}
	}

	.content_c {
		width: 100%;
		padding: .6rem 0.6rem 2.5rem .6rem;
		text-align: left;
		font-size: .28rem;
		box-sizing: border-box;

		img {
			margin-bottom: 10px;
			border-radius: 5px;
			width: 100%;
		}
		
		p{
			font-family: 'nstb';
			font-size: .42rem;
			color: #000;
			margin-bottom: 5px;
		}
	}
</style>