const th={
	qfh:'THB',
	jiantizhongwen:'Thailand',
    xianlu:'สาย', 
    dianwei:'ดัชนี',
    wodezichan:'ทรัพย์สินของฉัน',
    qingshuruchakanmaTs:'กรุณากรอกรหัสผ่านเพื่อดูหมายเลขบัตร',
    anquanmaTpsnew:'โปรดตั้งรหัสผ่านก่อน จัดการและดูการตั้งค่าบัตรธนาคาร',
    anquanmaTpsnewtwo:'เรียนคุณลูกค้า รหัสผ่านนี้เป็นรหัสผ่านของคุณในการดูบัตรธนาคารของคุณ มีวัตถุประสงค์เพื่อความปลอดภัยเพื่อป้องกันการรั่วไหลของข้อมูลบัตรธนาคารของคุณ',
    touzikuaixun:'ข่าวการลงทุน',
    jiaoyiyonghu:'ผู้ใช้ธุรกรรม',
    jiaoyipinglei:'หมวดหมู่ธุรกรรม',
    danlunhuoli:'กำไรรอบเดียว',
    jiaoyiriqi:'วันที่ทำรายการ',
    dengluhouchakan:'เข้าสู่ระบบเพื่อดู',
    shouye:'หน้าแรก',
    jiaoyidating:'ห้องโถง',
	jiaoyidatingabc:'Morgan Stanley ห้องโถง',
	zengzhi:'กองทุน',
    yucun:'จ่ายล่วงหน้า',
    zoushi:'แนวโน้ม',
    wode:'ของฉัน',
	gongsijieshao:'การแนะนำ',
	addwordsa:'ปัจจุบันเป็นแพลตฟอร์มการลงทุนที่มั่นคงที่สุดในโลก',
	addwordsb:'การวางแผนและคำแนะนำที่ดี',
	addwordsc:'ราคาตรงไปตรงมา',
    zuiditouzi:'การลงทุนขั้นต่ำ',
    zuidijinru:'รายการขั้นต่ำ', 
    jichulicai:'การลงทุนระดับเบื้องต้น',
    zishenlicai:'การลงทุนระดับกลาง',
    dashilicai:'การลงทุนระดับวีไอพี',
    zhuanjialicai:'การลงทุนระดับซุปเปอร์วีไอพี',
	基础理财:'การลงทุนระดับเบื้องต้น',
    资深理财:'การลงทุนระดับกลาง',
    大师理财:'การลงทุนระดับวีไอพี',
    专家理财:'การลงทุนระดับซุปเปอร์วีไอพี',
    huiyuanquanyi:'UID',
	huiyuanname:'เรียนผู้ใช้',/*ชื่อสมาชิก*/
	welcomeback:'ยินดีต้อนรับ',
    zuoriyingli:'กำไรเมื่อวาน',
    zongzichan:'สินทรัพย์รวม',
    chucunjin:'ยอดเงินในบัญชี',
    jinriyingli:'กำไรวันนี้',
    zhuanchu:'ถอนเงิน',
    cunru:'เงินฝาก',
    zhanghuxinxni:'ข้อมูลเกี่ยวกับบัญชี',
    lishizhangdan:'ประวัติทุรกรรม',
    zijinminxi:'รายละเอียดทุน',
    renwuzhongxin:'ศูนย์งาน',
    xiaoxigonggao:'ประกาศข่าว',
    tuijianfenxiang:'แนะนำให้แชร์',
    huodongzhongxin:'ศูนย์กิจกรรม',
    jibenziliao:'ข้อมูลพื้นฐาน',
    dengluzhanghao:'บัญชีเข้าสู่ระบบ',
    nicheng:'ชื่อ',
    shezhi:'ตั้งค่า',
    genggaixianlu:'เปลี่ยนเส้นทาง',
    guanyuwomen:'เกี่ยวกับเรา',
    xitongshezhi:'การตั้งค่าระบบ',
    yuyanshezhi:'ตั้งค่าภาษา',
    tuichudenglu:'ออกจากระบบ',
    bangdingyinhangka:'ผูกบัตรธนาคาร',
    bangdingxuniqianbao:'ผูกกระเป๋าเงินเสมือน',
    anquanma:'รหัสรักษาความปลอดภัย',
    xiugailenglumima:'เปลี่ยนรหัสผ่านเข้าสู่ระบบ',
    yinhangkaguanli:'การจัดการบัตรธนาคาร',
    tianjiayinhangka:'เพิ่มบัตรธนาคาร',
    xuniqianbaoguanli:'การจัดการกระเป๋าเงินเสมือน',
    tianjiaxuniqianbao:'เพิ่มกระเป๋าเงินเสมือน',
    chiakren:'ชื่อบัญชี',
    yinhangkakahao:'หมายเลขบัญชี',
    yinhangmingcheng:'ชื่อธนาคาร',
    yhkTps:'กรุณาผูกบัตรธนาคารของผู้ถือบัตร',
    nixiqianbaodizhi:'ที่อยู่กระเป๋าเงินเสมือน',
    xuniqianbaoleixing:'ประเภทกระเป๋าเงินเสมือน',
    xnqbTps:' หมายเหตุ：กรอกข้อมูลUSDTประเภทที่อยู่，ตัวอย่างเช่น：trc20、erc20、omni ',
    plchikaren:'กรุณากรอกชื่อเจ้าของบัญชีธนาคาร',
    plkahao:'กรุณากรอกหมายเลขบัญชีธนาคาร',
    plyinhangmingcheng:'กรุณากรอกชื่อธนาคาร',
    plqbdz:'โปรดป้อนที่อยู่กระเป๋าเงินเสมือน',
    plqblx:'โปรดป้อนประเภทกระเป๋าเงินเสมือน',
    shezhianquanma:'ตั้งรหัสความปลอดภัย',
    yuananquanma:'รหัสความปลอดภัยเดิม',
    xinanquanma:'รหัสความปลอดภัยใหม่',
    planquanma:'กรุณาใส่รหัสความปลอดภัยเดิม',
    plxinanquanma:'กรุณาใส่รหัสความปลอดภัยเดิม',
    queren:'ยืนยัน',
	quxiao:'ยกเลิก',
    anquanmaTps:' กรุณาตั้งรหัสความปลอดภัย，โปรดอย่าเหมือนกับรหัสผ่านบัตรธนาคาร',
    anquanmaTpsTow:' เรียนลูกค้า，รหัสความปลอดภัยนี้คือรหัสผ่านของคุณเมื่อทำการโอนย้ายออก，ด้วยเหตุผลด้านความปลอดภัย，โปรดพยายามอย่าให้เหมือนกับรหัสผ่านเข้าสู่ระบบ',
    xiugaidenglu:'เปลี่ยนรหัสผ่านเข้าสู่ระบบ',
    jiumima:'รหัสผ่านเก่า',
    xinmima:'รหัสผ่านใหม่',
    querenmima:'ยืนยันรหัสผ่าน',
    pljiumima:'กรุณาใส่รหัสผ่านเก่า',
    plxinmima:'กรุณาใส่รหัสผ่านใหม่',
    plquerenmima:'กรุณาใส่รหัสผ่านอีกครั้ง',
    wancheng:'สำเร็จ',
    kaishitouzi:'เริ่มลงทุน',
    kaiTps:'เมื่อสายปัจจุบันใช้งานไม่ได้，ลองเปลี่ยนไปใช้สายอื่น',
    zuiyou:'ดีที่สุด',
    dangaqianxianlu:'บรรทัดปัจจุบัน',
    dangqianbanben:'รุ่นปัจจุบัน',
    banquansuoyou:'สงวนลิขสิทธิ์',
    yinglizhendong:'แจ๊กพ็อตแตก',
    gensuixitong:'ทำตามระบบ',
    zhuanchujine:'ยอดโอน',
    plzhuanchu:'กรุณากรอกจำนวนเงินโอน',
    zhuanchuTps:'หมายเหตุ หากคุณมีคำถามใดๆโปรดติดต่อฝ่ายบริการลูกค้าของแพลตฟอร์ม。',
    xuanzeyh:'กรุณาเลือกบัตรธนาคารถอนเงิน',
    xuanzehb:'โปรดเลือกกระเป๋าเงินเสมือนสำหรับการถอน',
    tijiaoshenqing:'ดำเนินการถอน',
    shuruanquanma:'กรุณาใส่รหัสความปลอดภัย',
    xianshangcunru:'ฝากเงินออนไลน์',
    saomacunru:'USDT', 
    wangyincunru:"เงินฝากธนาคาร",
    shijian:'เวลา',
    upshijian:'เวลาเริ่มต้น',
    downshijian:'เวลาสิ้นสุด',
    wuTps:'ไม่มีข้อมูลที่เกี่ยวข้อง~',
    jiazaicx:'โหลดใหม่',
    di:'ที่',
    lun:'รอบ',
    weiyingli:'ไม่ได้กำไร',
    yishouli:'ได้รับการยอมรับ',
    zhuangtai:'สถานะ',
    chongzhi:'รีเซ็ต',
    riqi:'วันที่',
    CapActive:{
        chongzhi:'เติมเงิน',
        tixian:'ถอน',
        goumai:'ซื้อ',
        yingli:'กำไร',
        zengsong:'ของขวัญ',
        weitongguo:'ไม่ผ่าน',
        yitongguo:'ผ่าน',
        yijujue:'ปฏิเสธ',
    },
    zhanneixiaoxi:'ข่าวสถานี',
    pingtaigonggao:'ประกาศแพลตฟอร์ม',
    fuzhilanjie:"คัดลอกลิงค์",
    denglu:'เข้าสู่ระบบ',
    zhuce:'ลงทะเบียน',
    jizhumima:'จดจำรหัสผ่าน',
    wangjimima:'ลืมรหัสผ่าน',
    youkedenglu:'เข้าสู่ระบบผู้เยี่ยมชม',
    zhucezhanghao:"ลงทะเบียนบัญชี",
    plusername:'กรุณาใส่ชื่อผู้ใช้',
    plpassword:'กรุณาใส่รหัสผ่าน',
	plpasswordok:'กรุณากรอกรหัสผ่านอีกครั้ง',
	usernamerule:'ความยาวของชื่อผู้ใช้ต้องมี 6-12 ตัวอักษร',
	passwordrule:'รความยาวของรหัสผ่านคือ 6-12 ตัวอักษร',
	plmobile:'กรุณากรอกหมายเลขโทรศัพท์ของคุณ',
    wanchengzhuce:'การลงทะเบียนเสร็จสมบูรณ์',
    yiyouzhanghao:'บัญชีที่มีอยู่',
    qingdenglu:'กรุณาเข้าสู่ระบบ',
    remenhuati:'ประเด็นร้อน',
    news1:'ต้นฉบับ',
    news2:'แนะนำ',
    news3:'ความมั่งคั่ง',
    renliulan:'ผู้คนเรียกดู',
    hezhi:'และความคุ้มค่า',
    lunshu:'จำนวนรอบ',

    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'EUR',
    平单:'SGD',
    平双:'GBP',
	极阴:'JPY',
	极阳:'THB',
    qi:'ระยะ',
    juli:'รอบ',
    lunjiezhi:'จบรอบ',
    yifengpan:'ได้ปิดรอบแล้ว',
    yiguanbi:'ปิดแล้ว',
    yonghu:'ผู้ใช้',
    danlunjiaoyi:'การแลกเปลี่ยนรอบเดียว',
    caozuo:'วิธี', 
    pljoin:'เข้าร่วมห้องแชทสำเร็จ',
    pltingshou:'สินค้าหมดจำหน่าย',
    gengou:"ซื้อตาม",
   quedinggengou:"แน่ใจจะซื้อตามไหม",
   wanjia:"ผู้เล่น", 
   leibie:"ประเภท",
   jine:"จำนวนเงิน",
   gouru:'ซื้อเข้า',
   zanweigouru:'กรุณาเลือกและคลิกที่นี่เพื่อซื้อ',
   qigou:'ซื้อ',
   gourushuliang:'จำนวนซื้อเข้า',
   changgui:'ปกติ',
   shuzi:'ตัวเลข',
   yinglihezhi:'กำไรและมูลค่า',
   shouyijieshao:'รายได้แนะนำ',
   lijicunru:'ฝากเงินทันที',
   kaihaojieguo:'ผลการเปิดรหัส',
   kaihaoqushi:'แนวโน้มการเปิดรหัส',
   chanpingshuoming:'คู่มือสินค้า',
   qingchuhuancun:'ล้างแคช',
   youke:'ผู้เยี่ยมชม',
   gongxi:'ยินดีด้วย ',
   zai:'อยู่',
   yingli:'กำไร',
   xiadanchenggong:'ซื้อสำเร็จ',
   jiazaizhong:'กำลังโหลด...',
   guanbitg:'ปิดแล้ว, หยุด ซื้อเข้า',
   xiangoudl:'สามารถซื้อตามรอบนี้เท่านั้น',
   liaotiantip:'ระบบได้ปิดการแสดงความคิดเห็น, กรุณาเลือกซื้อ',
   tishi:'แจ้งเตือน',
   zhidao:'รับทราบแล้ว',
   zhuanchusb:'การโอนออก ล้มเหลว',
   zhuanchusbs:'จำนวนเงินไม่เพียงพอ, การโอนล้มเหลว',
   buyxq:'รายละเอียดการซื้อ',
   orderNo:'เลขที่บิน',
   plan_name:'ข้อมูลการจองห้อง',
   buyxx:'ข้อมูลการซื้อ',
   haoxx:'รายละเอียดการเลือกรหัส',
   buysj:'เวลาซื้อ',
   yilou:'ตกหล่น',
   fzjqb:'ได้คัดลอกไปยังที่วาง',
   chanpin1:'เวลาเปิดจำหน่าย: ทุกๆ3,5 นาทีคือ1รอบ, ทุกวัน10:00AM เปิดรอบ, ทุกวัน02:00AM ปิดรอบ', 
  chanpin2:'ทุกรอบมี3 ตัวเลข0-9 , สามตัวเลข รวมกันเป็นผลลัพธ์สุดท้าย, สามารถลงทุน ผลลัพธ์',
  Tips:{
        wjmm:'ลืมรหัสกรุณาติดต่อฝ่ายบริการเพื่อเปลี่ยนแปลง',
        dlcg:'ลงชื่อ เข้าใช้สำเร็จ',
        tjcg:'การส่งสำเร็จ',
        zanwu:'ไม่มีข้อมูล',
        buyizhi:'รหัสไม่ตรงกัน',
		buyizhia:'รหัสไม่ตรงกัน',
        zcbz:'ทรัพย์สินของท่านไม่เพียงพอกรุณาเติม',
        pltxje:'กรุณาใส่จำนวนที่จะถอน',
        zdtx:'ถอนขั้นต่ำ100',
    youke:'สวัสดีลูกค้าที่เคารพ, กรุณาสมัครสมาชิกก่อนการดำเนินการ',
    szcg:'การตั้งค่าสำเร็จ',
    },
  smrz:'ยืนยันบัญชี',
  smrzzm:'รูปบัตรประจำตัว',
  smrzxm:'ชื่อและนามสกุล',
  smrzhm:'เลขบัตรประจำตัว',
  smrztip:'ส่งสำเร็จ, กำลังรอการอนุมัติ',
  qwszl:'กรุณากรอกข้อมูลให้สำเร็จ',
  kfa:'ติดต่อฝ่ายบริการลูกค้า',
  kfb:'ฝ่ายบริการหมายเลข 2',
  kfc:'ฝ่ายบริการหมายเลข 3',
  aboutaa:'นับตั้งแต่ก่อตั้งบริษัทในปี พ.ศ. 2478 Morgan Stanley ได้ส่งมอบธุรกิจชั้นหนึ่งในรูปแบบชั้นหนึ่งมาโดยตลอด การสนับสนุนทุกสิ่งที่เราทำคือค่านิยมหลัก 5 ประการ ความเป็นผู้นำของ Morgan Stanley ทุ่มเทให้กับการดำเนินธุรกิจระดับเฟิร์สคลาสในแบบเฟิร์สคลาส คณะกรรมการและผู้บริหารระดับสูงของเราเชื่อว่าทุนสามารถและควรเป็นประโยชน์ต่อสังคมทั้งหมด',
  aboutab:'ทุกสิ่งที่เราทำที่ Morgan Stanley ได้รับการชี้นำโดยค่านิยมหลักห้าประการของเรา: ทำสิ่งที่ถูกต้อง ให้ความสำคัญกับลูกค้าเป็นอันดับแรก เป็นผู้นำด้วยแนวคิดที่ยอดเยี่ยม มุ่งมั่นในความหลากหลายและการไม่แบ่งแยก และการตอบแทน Morgan Stanley แตกต่างด้วยความสามารถของทีมงานที่หลากหลายของเรา วัฒนธรรมการเข้าถึงและการไม่แบ่งแยกของเราได้สร้างมรดกและกำหนดอนาคตของเรา ซึ่งช่วยเสริมสร้างความแข็งแกร่งให้กับธุรกิจของเราและนำคุณค่ามาสู่ลูกค้า',
  aboutac:'ที่ Morgan Stanley ลูกค้าต้องมาก่อน เราช่วยให้บุคคล ครอบครัว สถาบัน และรัฐบาลระดมทุน จัดการ และแจกจ่ายเงินทุนที่พวกเขาต้องการเพื่อให้บรรลุเป้าหมาย สำรวจ Morgan Stanley เราช่วยให้ผู้คน ธุรกิจ และสถาบันสร้าง อนุรักษ์ และจัดการความมั่งคั่ง เพื่อให้พวกเขาสามารถบรรลุเป้าหมายทางการเงินที่ Morgan Stanley เราเป็นผู้นำด้วยแนวคิดที่ยอดเยี่ยม เรานำเสนอข้อมูลเชิงลึกที่เฉียบแหลมเกี่ยวกับประเด็นที่สำคัญที่สุดในปัจจุบันในทุกธุรกิจของเรา',
  aboutad:'เราทำอะไร',

  xzyouxishuoming:'กรุณาเลือกทิศทางการลงทุน',
  kefu:'บริการลูกค้า',
  meiyouzhanghao:'ไม่มีบัญชี ?',
  shifangshuaxin:'ดึงลงเพื่อรีเฟรช',
  xialashuaxin:'ดึงลงเพื่อรีเฟรช',
  tijiaoanniu: 'ส่ง',
  
  womenshishui: 'พวกเราคือใคร?',
  wmjs: 'ที่ Morgan stanley เราได้รับคำแนะนำจากจุดประสงค์ร่วมกันในการช่วยทำให้ชีวิตทางการเงินดีขึ้นผ่านพลังของทุกการเชื่อมต่อ',
  wmjsb: 'ส่งมอบการเติบโตอย่างมีความรับผิดชอบ',
  tohome: 'เข้าหน้าหลัก',
  huancun: 'รีเฟรชหน้า',/*刷新页面*/
  wfcw: 'ยอดคงเหลือไม่เพียงพอ โปรดเติมเงิน',/*餘額不足，請儲值*/
  dccw: 'ขณะนี้ปิดและไม่สามารถซื้อได้',/*目前已關閉且無法購買*/
  dzcw: 'หากคุณมีคำสั่งซื้อที่ยังไม่ได้ดำเนินการ โปรดติดต่อฝ่ายบริการลูกค้า',/*有未处理订单，请联系客服*/
  dwcw: 'ยอดคงเหลือไม่เพียงพอสำหรับการถอน', /*余额不足，无法提款*/
  dxcw: 'รหัสผ่านกองทุนผิดพลาด',/*资金密码错误*/
  fk: 'ข้อเสนอแนะ',
  
  'Parameter error':'ข้อผิดพลาดของพารามิเตอร์',
  '可用余额':'ยอดเงินคงเหลือ',
  '已存储金额':'จำนวนเงินที่เก็บไว้',
  '预计收益':'รายได้โดยประมาณ',
  '投资日期':'วันที่ลงทุน',
  '开始计算利息':'เวลาคำนวณดอกเบี้ย',
  '结算时间':'เวลาชำระบัญชี',
  '自动复购':'การซื้อคืนอัตโนมัติ',
  '储蓄金额':'ยอดเงินออม',
  '请输入储蓄金额':'กรุณากรอกจำนวนเงินออมทรัพย์',
  '全部投入':'ทั้งหมดเข้า',
  '购买':'ซื้อโครงการลงทุน',
  
  'jjzhangdan':'บิลกองทุน',
  '待结算':'รอการตั้งถิ่นฐาน',
}
export default th;