<template>
	<div class="addBankCard">
		<div class="settingwarp">
			<header>
				<div class="g-header">
					<div class="left" @click="changego">
						<van-icon name="arrow-left" color="#aaa" size="18px" />
					</div>
					<div class="middle fs-18">{{$t('tianjiayinhangka')}}</div>
					<div class="right">
						<div class="bg-icon bg-icon-setting"></div>
					</div>
				</div>
				<div class="blockHeight"></div>
			</header>
			<div class="g-content">
				<div class="tips c_a0a0a0">{{$t('yhkTps')}}</div>
				<div class="form">
					<van-field v-model="acc" :placeholder="$t('plchikaren')">
						<template #label>
							<span class="addtitle">{{$t('chiakren')}}</span>
						</template>
					</van-field>
					<van-field v-model="acc_name" :placeholder="$t('plkahao')"><!-- type='number' -->
						<template #label>
							<span class="addtitle">{{$t('yinhangkakahao')}}</span>
						</template>
					</van-field>
					<van-field v-model="acc_bank" :placeholder="$t('plyinhangmingcheng')">
						<template #label>
							<span class="addtitle">{{$t('yinhangmingcheng')}}</span>
						</template>
					</van-field>
					<!-- <van-field v-model="value" placeholder=this.$t('plusername')>
            <template #label>
              <span class="addtitle">开户地点</span>
            </template>
          </van-field>
                <van-field v-model="value" placeholder=this.$t('plusername')>
            <template #label>
              <span class="addtitle">开户支行</span>
            </template>
          </van-field> -->
					<van-cell>
						<van-button type="primary" round block class="Btnlogin"
							@click="btnSubmit">{{$t('tijiaoanniu')}}</van-button>
					</van-cell>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import userApi from "@/api/user";
	export default {
		name: "addBankCard",
		components: {},
		data() {
			return {
				acc: "",
				acc_name: "",
				acc_bank: "",
				mark: "",
				type: 0,
			};
		},
		methods: {
			changego() {
				this.$router.go(-1);
			},
			btnSubmit() {
				if (!this.acc) {
					this.$toast(this.$t('plchikaren'));
				} else if (!this.acc_name) {
					this.$toast(this.$t('plkahao'));
				} else if (!this.acc_bank) {
					this.$toast(this.$t('plyinhangmingcheng'));
				} else {
					let that = this
					userApi.setPayInfo(this.acc, this.acc_name, this.acc_bank, '', this.type).then((data) => {
						console.log(data.msg);
						this.$toast.loading({
							message: this.$t('Tips.tjcg'),
							forbidClick: true,
							duration: 500,
							onClose() {
								that.$router.go(-1);
							},
						});
					}).catch((err) => {
						this.$toast.fail(this.$t(err.msg));
					});
				}
			},
		},
		mounted() {},
	};
</script>
<style lang="less" scoped>
	.settingwarp {
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.g-content {
		width: 100%;
		background-color: #fff;
	}

	.tips {
		padding: 0.5rem 0.4rem .2rem .4rem;
		text-align: left;
		font-size: 0.32rem;
	}

	.c_a0a0a0 {
		color: #999;
	}

	.addtitle {
		color: #999;
		font-size: .32rem;
	}

	.van-cell::after {
		position: absolute;
		box-sizing: border-box;
		content: " ";
		pointer-events: none;
		bottom: 0;
		border-bottom: 1px solid #f0f0f0;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	/deep/.van-field__control {
		color: #000;
		font-size: .32rem;
	}

	/deep/ .van-button--round {
		border-radius: 5px;
	}

	.Btnlogin {
		margin-top: 0.5rem;
		color: #fff;
		background-color: #E31837;
		border: none;
	}
</style>