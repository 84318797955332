const es={
	qfh:'THB',
    jiantizhongwen:'México',
    xianlu:'Línea',
    dianwei:'Punto',
    wodezichan:'Mis activos',
	qingshuruchakanmaTs:'Por favor ingrese su contraseña para ver su número de tarjeta.',
	anquanmaTpsnew:'Primero establezca una contraseña. Administrar y ver la configuración de la tarjeta bancaria.',
	anquanmaTpsnewtwo:'Estimado cliente Esta contraseña es su contraseña para ver su tarjeta bancaria. Su objetivo de seguridad es evitar la filtración de la información de su tarjeta bancaria.',
    touzikuaixun:'Noticias de inversión',
    jiaoyiyonghu:'Usuario',
    jiaoyipinglei:'Categoría',
    danlunhuoli:'Ganancia',
    jiaoyiriqi:'Fecha',
    dengluhouchakan:'Ver después de iniciar sesión',
    shouye:'Hogar',
    jiaoyidating:'Sala',
	jiaoyidatingabc:'Sala',
    yucun:'Prestador',
    zoushi:'Tendencia',
    wode:'Mío',
    zuiditouzi:'Inversión mínima',
    zuidijinru:'Entrada mínima',
    jichulicai:'Financiación básica',
    zishenlicai:'Financiamiento senior',
    dashilicai:'Financiación maestra',
    zhuanjialicai:'Financiación experta',
	基础理财:'Financiación básica',
    资深理财:'Financiamiento senior',
    大师理财:'Financiamiento Maestro',
    专家理财:'Financiación experta',
    huiyuanquanyi:'U-id',
	huiyuanname:'U-name',
    zuoriyingli:'Ganancias ayer',
    zongzichan:'Activos totales',
    chucunjin:'Dinero',
    jinriyingli:'Ganancias hoy',
    zhuanchu:'Retirar',
    cunru:'Depósito',
    zhanghuxinxni:'Información de la cuenta',
    lishizhangdan:'Facturas históricas',
    zijinminxi:'Detalles de la financiación',
    renwuzhongxin:'Centro de misión',
    xiaoxigonggao:'Anuncio de noticias',
    tuijianfenxiang:'Recomendado para compartir',
    huodongzhongxin:'Centro de actividades',
    jibenziliao:'Información básica',
    dengluzhanghao:'Iniciar sesión en la cuenta',
    nicheng:'Apodo',
    shezhi:'Ajustes',
    genggaixianlu:'Cambiar linea',
    guanyuwomen:'Sobre nosotros',
    xitongshezhi:'Configuración del sistema',
    yuyanshezhi:'Configuración de idioma',
    tuichudenglu:'Cerrar sesión',
    bangdingyinhangka:'Vincular tarjeta bancaria',
    bangdingxuniqianbao:'Vincular billetera virtual',
    anquanma:'Código de seguridad',
    xiugailenglumima:'Modificar contraseña de inicio de sesión',
    yinhangkaguanli:'Gestión de tarjetas bancarias',
    tianjiayinhangka:'Añadir una tarjeta bancaria',
    xuniqianbaoguanli:'Gestión de billetera virtual',
    tianjiaxuniqianbao:'Añadir billetera virtual',
    chiakren:'Titular de la tarjeta',
    yinhangkakahao:'Número de tarjeta bancaria',
    yinhangmingcheng:'Nombre del banco',
    yhkTps:'Por favor, vincule la tarjeta bancaria del titular de la tarjeta.',
    nixiqianbaodizhi:'Dirección de la billetera',
    xuniqianbaoleixing:'Tipo de billetera',
    xnqbTps:'Recordatorio: complete el tipo de dirección USDT, como: trc20, erc20, omni.',
    plchikaren:'Por favor ingrese el titular de la tarjeta',
    plkahao:'Por favor, introduzca el número de tarjeta del titular de la tarjeta',
    plyinhangmingcheng:'Por favor, introduzca el nombre del banco',
    plqbdz:'Por favor ingrese la dirección de la billetera virtual',
    plqblx:'Por favor ingrese el tipo de billetera virtual',
    shezhianquanma:'Establecer código de seguridad',
    yuananquanma:'Código de seguridad original',
    xinanquanma:'Nuevo código de seguridad',
    planquanma:'Por favor, introduzca el código de seguridad original',
    plxinanquanma:'Por favor, introduzca un nuevo código de seguridad',
    queren:'Confirmar',
	quxiao:'Cancelar',
    anquanmaTps:'Por favor, establezca un código de seguridad, que no sea el mismo que la contraseña de la tarjeta bancaria',
    anquanmaTpsTow:'Estimado cliente, este código de seguridad es la contraseña que utilizará al realizar la transferencia. Por razones de seguridad, intente que no sea la misma que la contraseña de inicio de sesión.',
    xiugaidenglu:'Modificar contraseña de inicio de sesión',
    jiumima:'Contraseña anterior',
    xinmima:'Nueva contraseña',
    querenmima:'Confirmar Contraseña',
    pljiumima:'Por favor, introduzca la contraseña anterior',
    plxinmima:'Por favor, introduzca una nueva contraseña',
    plquerenmima:'Por favor, introduzca la contraseña de nuevo',
    wancheng:'Completo',
    kaishitouzi:'Empiece a invertir',
    kaiTps:'Cuando la línea actual no esté disponible, intente cambiar a otras líneas',
    zuiyou:'Óptimo',
    dangaqianxianlu:'Línea actual',
    dangqianbanben:'Versión actual',
    banquansuoyou:'Derechos de autor',
    yinglizhendong:'Impacto de ganancias',
    gensuixitong:'Por defecto',
    zhuanchujine:'Cantidad',
    plzhuanchu:'Por favor ingrese el monto de la transferencia',
    zhuanchuTps:'Consejos: Si tiene alguna pregunta, comuníquese con el servicio de atención al cliente de la plataforma.',
    xuanzeyh:'Por favor seleccione una tarjeta bancaria para realizar el retiro',
    xuanzehb:'Seleccione una billetera virtual para realizar el retiro',
    tijiaoshenqing:'Presentar solicitud',
    shuruanquanma:'Por favor ingrese el código de seguridad',
    xianshangcunru:'Depósito en línea',
    saomacunru:'USDT',
    wangyincunru:"Depósito bancario",
    shijian:'Tiempo',
    upshijian:'Hora de inicio',
    downshijian:'Fin del tiempo',
    wuTps:'Sin datos ~',
    jiazaicx:'Recargar',
    di:'Período ',
    lun:' ',
    weiyingli:'Improductivo',
    yishouli:'Aceptado',
    zhuangtai:'Estado',
    chongzhi:'Reiniciar',
    riqi:'Fecha',
    CapActive:{
        chongzhi:'Recargar',
        tixian:'Retirar',
        goumai:'Comprar',
        yingli:'Ganancia',
        zengsong:'Donar',
        weitongguo:'No pasó',
        yitongguo:'Aprobado',
        yijujue:'Rechazado',
    },
    zhanneixiaoxi:"Información",
    pingtaigonggao:'Anuncio',
    fuzhilanjie:"Copiar enlace",
    denglu:'Acceso',
    zhuce:'Registro',
    jizhumima:'Recordar',
    wangjimima:'Has olvidado tu contraseña',
    youkedenglu:'Inicio de sesión de visitantes',
    zhucezhanghao:"Registrar cuenta",
    plusername:'Por favor, introduzca el nombre de usuario',
    plpassword:'Por favor, introduzca la contraseña',
    wanchengzhuce:'Completo',
    yiyouzhanghao:'Tener una cuenta',
    qingdenglu:'Por favor, inicia sesión',
    remenhuati:'Tema candente',
    news1:'Original',
    news2:'Recomendar',
    news3:'Activo',
    renliulan:'Navegar',
    hezhi:'Y valor',
    lunshu:'Período',

    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'UER',
    平单:'SGD',
    平双:'GBP',
    极阴:'JPY',
    极阳:'THB',
    qi:'',
    juli:'Distancia',
    lunjiezhi:'Fin',
    yifengpan:'Deja de apostar',
    yiguanbi:'Cerrado',
    yonghu:'Usuario',
    danlunjiaoyi:'Comercio',
    caozuo:'Funcionar',
    pljoin:'Unirse a la sala de chat con éxito',
    pltingshou:'El producto ha sido descontinuado',
    gengou:"Seguir",
	quedinggengou:"Estás seguro de seguir la compra?",
	wanjia:"Jugador",
	leibie:"Categoría",
	jine:"Cantidad",
    gouru:'Comprar',
    zanweigouru:'Por favor seleccione y haga clic aquí para comprar',
    qigou:'Compra',
    gourushuliang:'Cantidad de compra',
    changgui:'Convencional',
    shuzi:'Número',
    yinglihezhi:'Beneficio y valor',
    shouyijieshao:'Ingreso',
    lijicunru:'Depósito',
    kaihaojieguo:'Registro',
    kaihaoqushi:'Tendencia',
    chanpingshuoming:'Descripción',
    qingchuhuancun:'Limpiar caché',
    youke:'Visitante',
	gongxi:'Felicitaciones a',
	zai:'en el',
	yingli:'Ganancia',
	xiadanchenggong:'Pedido realizado con éxito',
	jiazaizhong:'Cargando...',
	guanbitg:'Cerrado, dejar de comprar',
	xiangoudl:'Sigue únicamente la ronda actual',
	liaotiantip:'El sistema ha sido baneado, por favor elija comprar',
	tishi:'Consejo',
	zhidao:'DE ACUERDO',
	zhuanchusb:'No se pudo realizar la transferencia',
	zhuanchusbs:'Saldo insuficiente, transferencia fallida',
	buyxq:'Detalles de la compra',
	orderNo:'Proyecto de ley N°',
	plan_name:'Información del salón',
	buyxx:'Comprar información',
	haoxx:'Detalles de selección de números',
	buysj:'Ganar tiempo',
	yilou: 'desaparecido',
	fzjqb: 'Copiado al portapapeles',
	chanpin1: 'Horario de venta: cada 3,5 minutos es una ronda, abriendo a las 10:00 horas del mismo día y cerrando a las 02:00 horas del día siguiente.',
	chanpin2: 'Hay 3 números del 0 al 9 en cada ronda. La suma de los 3 números es el resultado final. Puedes invertir en el resultado: Muchos, Corto, Plano Simple, Plano Doble, Muchos Simples, Muchos Dobles, Corto Simple, Corto Doble, Cátodo, Ánodo y número.',
	Tips:{
        wjmm: 'Olvidó su contraseña? ¡Contáctese con atención al cliente para modificarla!',
        dlcg: 'Inicio de sesión exitoso',
        tjcg: 'Enviado exitosamente',
        zanwu: 'Sin datos',
        buyizhi: 'Las dos contraseñas son inconsistentes',
        zcbz: 'Sus activos son insuficientes, por favor deposite',
        pltxje: 'Por favor ingrese el monto a retirar',
        zdtx: 'Retiro mínimo 100',
		youke: 'Estimados turistas: Por favor regístrese como miembro pleno y opere',
		szcg: 'Establecido exitosamente',
     },
	smrz:'Autenticación de nombre real',
	smrzzm: 'Autenticación positiva',
	smrzxm:'Nombre',
	smrzhm:'Número de documento de identidad',
	smrztip: 'Envío exitoso, ¡esperando revisión!',
	qwszl: 'Por favor complete la información',
	kfa:'Servicio al cliente nº1',
	kfb:'Servicio al cliente nº2',
	kfc:'Servicio al cliente nº3',
	aboutaa:'Desde nuestra fundación en 1935, Morgan Stanley ha realizado negocios de primera clase de manera consistente. Todo lo que hacemos se sustenta en cinco valores fundamentales. El liderazgo de Morgan Stanley está dedicado a realizar negocios de primera clase de una manera de primera clase. Nuestro directorio y nuestros ejecutivos sénior creen que el capital puede y debe beneficiar a toda la sociedad.',
	aboutab:'Todo lo que hacemos en Morgan Stanley se guía por nuestros cinco valores fundamentales: hacer lo correcto, priorizar a los clientes, liderar con ideas excepcionales, comprometerse con la diversidad y la inclusión, y contribuir. Morgan Stanley se diferencia por el calibre de nuestro equipo diverso. Nuestra cultura de acceso e inclusión ha construido nuestro legado y da forma a nuestro futuro, ayudando a fortalecer nuestro negocio y a aportar valor a los clientes.',
	aboutac:'En Morgan Stanley, los clientes son lo primero. Ayudamos a individuos, familias, instituciones y gobiernos a recaudar, administrar y distribuir el capital que necesitan para alcanzar sus objetivos. Explora Morgan Stanley. Ayudamos a personas, empresas e instituciones a generar, preservar y administrar su patrimonio para que puedan alcanzar sus objetivos financieros. En Morgan Stanley, lideramos con ideas excepcionales. En todos nuestros negocios, ofrecemos una visión profunda de los problemas más críticos de la actualidad.',
	aboutad:'Qué hacemos',
	
	xzyouxishuoming:'Por favor seleccione una dirección de inversión',
	kefu:'Servicio al cliente',
	meiyouzhanghao:'No tienes cuenta?',
	shifangshuaxin:'Bajar para actualizar',
	xialashuaxin:'Bajar para actualizar',
	tijiaoanniu: 'Enviar',
	
	womenshishui: 'Quienes somos nosotros',
	wmjs: 'Nuestra ventaja es combinar nuestras capacidades globales con un profundo conocimiento de los mercados locales.',
	wmjsa: 'Desplegamos equipos de expertos en inversiones en todo el mundo para proteger su cartera de inversiones. Por supuesto, también podemos adaptarnos a las circunstancias y aprovechar las oportunidades de inversión favorables a corto plazo.',
	wmjsc: 'La Oficina de Inversiones (CIO) de Morgan stanley elabora estrategias',
	wmjsb: 'Descubra oportunidades de inversión para clientes',
	tohome: 'Ir a la página de inicio',
	huancun: 'Actualizar la página',/*刷新页面*/
	wfcw: 'El saldo no es suficiente. Por favor, recargue',/*餘額不足，請儲值*/
	dccw: 'Actualmente está cerrado y no está disponible para comprar.',/*目前已關閉且無法購買*/
	dzcw: 'Si tiene un pedido que aún no ha sido procesado, comuníquese con el servicio de atención al cliente.',/*有未处理订单，请联系客服*/
	dwcw: 'Saldo insuficiente para retiro', /*余额不足，无法提款*/
	dxcw: 'Error de contraseña del fondo',/*资金密码错误*/
	gongsijieshao: 'Acerca de',
	fk: 'Servicio',
	
	welcomeback:'Bienvenido de nuevo',
	zengzhi:'Financiar',
	'Parameter error':'Error de parámetro',
	'可用余额':'Saldo disponible',
	'已存储金额':'Cantidad almacenada',
	'预计收益':'Ingresos estimados',
	'投资日期':'Fecha de inversión',
	'开始计算利息':'Comience a calcular el interés',
	'结算时间':'Tiempo de liquidación',
	'自动复购':'Recompra automática',
	'储蓄金额':'Cantidad de ahorro',
	'请输入储蓄金额':'Por favor ingrese el monto de ahorro',
	'全部投入':'Todo dentro',
	'购买':'Comprar',
	
	'jjzhangdan':'Factura de fondos',
	'待结算':'Pendiente de liquidación',
}
export default es;