<template>
	<div class="register">

		<div class="close-btn" @click="changeHome">
			<i class="icon-x"></i>
		</div>
		<div class="login-head-c">
			<img src="@/assets/image/logo.svg" alt="" />
		</div>

		<div class="g-content">
			<div class="lrtitle">{{$t('zhuce')}}</div>

			<van-field :placeholder="$t('plusername')" v-model="username" clearable>
				<template #left-icon>
					<img src="@/assets/image/user.png" class="left-form-icon" alt="" />
				</template>
			</van-field>

			<van-field v-model="password" :type="switchPassType ? 'text' : 'password'" name="password"
				:right-icon="switchPassType ? 'eye' : 'closed-eye'" :placeholder="$t('plpassword')"
				@click-right-icon="switchPassType = !switchPassType">
				<template #left-icon>
					<img src="@/assets/image/pwd.png" class="left-form-icon" alt="" />
				</template>
			</van-field>

			<van-field v-model="passwordok" :type="switchPassTypea ? 'text' : 'password'" name="passwordok"
				:right-icon="switchPassTypea ? 'eye' : 'closed-eye'" :placeholder="$t('plpasswordok')"
				@click-right-icon="switchPassTypea = !switchPassTypea">
				<template #left-icon>
					<img src="@/assets/image/pwdck.png" class="left-form-icon" alt="" />
				</template>
			</van-field>

			<!-- <van-field :placeholder="$t('plmobile')" v-model="mobile" clearable>
				<template #left-icon>
					<img src="@/assets/image/user.png" class="left-form-icon" alt="" />
				</template>
			</van-field> -->

			<van-button type="primary" round block class="Btnlogin"
				@click="ChangeRegister">{{ $t("wanchengzhuce") }}</van-button>

			<div class="has-acoount">
				<span>{{ $t("yiyouzhanghao") }}?</span>
				<span class="btn-tologin" @click="changeLogin">{{$t("qingdenglu")}}</span>
			</div>

		</div>

	</div>
</template>

<script>
	// @ is an alias to /src
	import loginApi from "@/api/login";
	import storeAction from "@/store/typed-actions";
	import {
		ref
	} from "vue";
	export default {
		name: "Register",
		components: {},
		data() {
			return {
				username: "",
				password: "",
				passwordok: "",
				inviter_id: "",
			};
		},
		setup() {
			// const loginPassword = ref('');
			const switchPassType = ref(false);
			const switchPassTypea = ref(false);
			return {
				// loginPassword,
				switchPassType,
				switchPassTypea,
			};
		},
		methods: {
			changeLogin() {
				this.$router.push({
					path: "/login",
				});
			},
			changeHome() {
				this.$router.push({
					path: "/",
				});
			},
			ChangeRegister() {
				if (!this.username) {
					this.$toast(this.$t('plusername'));
				} else if (!/^.{6,12}$/.test(this.username)) {
					this.$toast(this.$t('usernamerule'));
				} else if (!this.password) {
					this.$toast(this.$t('plpassword'));
				} else if (!/^.{6,12}$/.test(this.password)) {
					this.$toast(this.$t('passwordrule'));
				} else if (!this.passwordok) {
					this.$toast(this.$t('plpassword'));
				} else if (!/^.{6,12}$/.test(this.passwordok)) {
					this.$toast(this.$t('passwordrule'));
				} else if (this.password != this.passwordok) {
					this.$toast(this.$t('Tips.buyizhia'));
				} else {
					loginApi
						.register(this.username, this.password, "1", this.inviter_id)
						.then((data) => {
							console.log(data.data, "注册成功");
							storeAction.loginDone(data.data);
						})
						.catch((err) => {
							this.$toast.clear();
							this.$toast.fail(this.$t(err.msg));
						});
				}
			},
		},
		mounted() {
			// http://localhost:8080/register?inviter_id=37
			console.log(this.$route.query.inviter_id);
			this.inviter_id = this.$route.query.inviter_id;
		},
	};
</script>
<style lang="less" scoped>
	.register {
		// background: rgb(40, 195, 100);
		// background: linear-gradient(160deg, rgba(40, 195, 100, 1) 0%, rgba(0, 150, 60, 1) 40%);
		background: url("../assets/image/loginbg.svg");
		background-size: 90%;
		background-position: 1.2rem 2rem;
		background-repeat: no-repeat;
	}

	.close-btn {
		position: absolute;
		z-index: 1;
		right: .6rem;
		top: .5rem;
	}

	.icon-x {
		display: inline-block;
		width: 0.5rem;
		height: 0.07rem;
		background: #aaa;
		line-height: 0;
		font-size: 0;
		vertical-align: middle;
		transform: rotate(45deg);
	}

	.icon-x::after {
		content: "";
		display: block;
		width: 0.5rem;
		height: 0.07rem;
		background: #aaa;
		transform: rotate(-90deg);
	}

	.g-content {
		position: absolute;
		bottom: 3vh;
		width: 100%;
		padding: 0 .6rem;
		// overflow: scroll;
	}

	.login-head-c {
		position: absolute;
		top: .45rem;
		text-align: left;
		width: 100%;
		padding: 0 .6rem;
		box-sizing: border-box;

		img {
			width: 180px;
			display: block;
		}
	}

	// .login-body {
	// 	margin-top: 5.7rem;
	// 	padding: 0 1.68rem;
	// }
	.lrtitle {
		width: 100%;
		text-align: left;
		margin-bottom: 20px;
		font-family: 'nstb';
		color: #000;
		font-size: .5rem;
	}

	.van-cell-group {
		background: unset;
		position: unset;
		border: none;

		input {}
	}

	.van-cell {
		position: relative;
		display: flex;
		box-sizing: border-box;
		width: 100%;
		padding: 15px 0;
		overflow: hidden;
		color: unset;
		font-size: .32rem;
		line-height: .32rem;
		background-color: unset;
	}

	.van-field::after {
		position: absolute;
		box-sizing: border-box;
		content: " ";
		pointer-events: none;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: .02rem solid #ddd !important;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	/deep/.van-checkbox__icon .van-icon {
		border: 1px solid #aaa;
	}

	/deep/.van-checkbox__icon--checked .van-icon {
		background-color: #E31837;
		border: 1px solid #E31837;
		color: #fff;
	}

	.left-form-icon {
		width: 20px;
	}

	.c_a0a0a0 {
		color: #aaa;
	}

	.register-bottom {
		margin-top: 0.5rem;
		flex: 1;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.Btnlogin {
		color: #fff;
		background: #002c51;
		// background: linear-gradient(160deg, rgba(0, 150, 60, 1) 0%, rgba(40, 195, 100, 1) 40%);
		border: none;
		font-family: 'nstb';
		margin-top: 20px;
		margin-bottom: 10px;
	}

	/deep/ .van-button--round {
		border-radius: 5px;
	}

	.has-acoount {}

	.btn-tologin {
		margin-left: 5px;
		color: #E31837;
		font-family: 'nstb';
	}

	.login-head {
		position: relative;
		margin-bottom: 40px;
	}

	.app-logo {
		height: 1.8rem;
	}
</style>