<template>
	<div class="lt-list_bg">
		<div class="jpm_logo_middle">
			<p>{{ $t("wmjsb") }}</p>
			<p>{{ $t("wmjs") }}</p>
		</div>

		<div class="lt-list">
			<div class="lt-item" v-for="(item, index) in list" :key="index" @click="changelottery">
				<div class="t1"><img :src="item.mark" class="guoqi" /></div>
				<div class="t2">{{item.name}}<!-- {{item.issue.last_issue}}{{$t('lun')}}<br/> --></div>
				<div class="t3">{{$t('dianwei')}} {{item.issue.last_code||0}}</div>
			</div>
		</div>
	</div>
</template>
<script>
	import homeApi from "@/api/home";
	export default {
		data() {
			return {
				list: [],
			};
		},
		computed: {},
		methods: {
			info() {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				homeApi
					.Lotteryinfo(1)
					.then((data) => {
						console.log(data.data);
						this.list = data.data.gameList
						this.$toast.clear();
					})
					.catch((err) => {
						this.$toast.clear();
						this.$toast.fail(err.msg);
					});
			},
			changelottery() {
				this.$router.push({
					path: "/lottery"
				});
			},
		},
		mounted() {},
		created() {
			this.info();
		},
	};
</script>
<style lang="less" scoped>
	.lt-lis {
		display: none;
	}
	
	.lt-list_bg{
		background: url(../../assets/image/lbbg.jpg);
		background-position: top;
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.lt-list {
		// display: flex;
		// justify-content: start;
		// align-items: stretch;
		// padding: 0 0.2rem;
		// box-sizing: border-box;
		// white-space: nowrap;
		// overflow-x: scroll;
		// -webkit-overflow-scrolling: touch;
		// overflow-y: hidden;
		// padding-bottom: 20px;
		padding-top: 0;
		padding-right: 0.6rem;
		padding-bottom: 0.4rem;
		padding-left: 0.6rem;
		box-sizing: border-box;
		// background: #fff;
	}


	.lt-item {
		display: inline-block;
		box-sizing: border-box;
		width: calc(33.33% - 0.2rem);
		margin-right: 0.3rem;
		margin-bottom: 0.3rem;
		background: #fff;
		// border: 0.02rem solid #eee;
		text-align: center;
		border-radius: 8px;
		padding: 0.35rem 0 0.35rem 0;
	}

	.lt-item:nth-child(3n+3) {
		margin-right: 0;
	}

	.t1 {
		color: #fff;
		font-size: 0.35rem;
	}

	.guoqi {
		border-radius: 50%;
		width: 35px;
		margin-bottom: 0.15rem;
		background: #fff;
		padding: 0;
	}

	.t2 {
		color: #000;
		font-size: 0.32rem;
		word-break: break-all;
		white-space: normal;
		font-family: 'nstb';
	}

	.t3 {
		color: #aaa;
		font-size: 0.26rem;
		line-height: .26rem;
		margin-top: 2px;
	}


	.jpm_logo_middle {
		padding: 1.5rem 1.6rem 1.5rem 0.6rem;
		text-align: left;
		color: rgba(0, 0, 0, 0.3);

		p:nth-child(1) {
			color: #000;
			font-family: 'nstb';
			font-size: 0.65rem;
			line-height: 0.75rem;
			margin-bottom: 5px;
		}

		p:nth-child(2) {
			font-size: 0.26rem;
			line-height: 0.44rem;
		}

	}
</style>